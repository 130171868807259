import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = [ "title", "characterCount" ]

    initialize() {
        this.update()
    }

    update() {
        var count = this.titleTarget.value.length
        if (count == 1) {
            var message = "1 Zeichen"
        } else {
            var message = `${count} Zeichen`
        }

        this.characterCountTarget.textContent = message

        if (count > 50) {
            this.characterCountTarget.classList.add("text-danger")
        } else {
            this.characterCountTarget.classList.remove("text-danger")
        }
    }

    submit(event) {
        var count = this.bodyTarget.value.length
        if (count > 50) {
            event.preventDefault()
        }
        this.element.reset()
    }

    // Define a new method called addGenderSpecifier
    addGenderSpecifier() {
        const input = this.titleTarget;
        const value = input.value.trim();


        // Check if the input field value contains (m/w/d) or (m/f/d) or '*in'
        if (!value.includes('(m/w/d)') && !value.includes('(m/f/d)') && !value.includes('*in')) {
            // Add a delay of 3 seconds before adding the gender specifier
            // only type if the inout is not focused and the user is not typing

                setTimeout(() => {
                    input.value = `${value} (m/w/d)`;
                    // flash input once
                    input.classList.remove('animate__animated');
                    input.classList.add('animate__animated');
                    input.classList.add('animate__pulse');
                }, 3000);

        }
    }

}